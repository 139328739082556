import axios from 'axios'
import qs from "qs";
import { getLocalStorageStr } from './common'
import { apiUrl, storeUrl } from './config'


const instance = axios.create({
    baseURL: apiUrl,
    timeout: 10000
})

export const get = async (url, data = {}) => {
    let res = await initRequestHeader('get', url, data);
    data = res.data;
    return new Promise((resolve, reject) => {
        instance.get(res.url, { params: data, headers: res.headers }).then((response) => {
            if (response.data.state == 266) {
                //清除全部缓存
                // localStorage.clear();
                //跳转登录页
                let des_url = window.location.href;
                if (des_url.indexOf('redirect=') != -1) {
                    des_url = des_url.substring(0, des_url.indexOf('redirect=') - 1);
                }
                window.location.href = storeUrl + '/user/login?redirect=' + encodeURIComponent(des_url);
                window.reload();
            } else {
                resolve(response.data)
            }
        }, err => {
            reject(err)
        })
    })
}

export const post = async (url, data = {}, type = 'urlencoded') => {
    let res = await initRequestHeader('post', url, data, type);
    return new Promise((resolve, reject) => {
        instance.post(res.url, res.data, {
            headers: res.headers
        }).then((response) => {
            if (response.data.state == 266) {
                //清除全部缓存
                // localStorage.clear();
                //跳转登录页
                let des_url = window.location.href;
                if (des_url.indexOf('redirect=') != -1) {
                    des_url = des_url.substring(0, des_url.indexOf('redirect=') - 1);
                }
                window.location.href = storeUrl + '/user/login?redirect=' + encodeURIComponent(des_url);
                window.reload();
            } else {
                resolve(response.data)
            }
        }, err => {
            reject(err)
        })
    })
}

/**
 * 初始化请求头和数据
 * @zjf-2020-12-25
 */
const initRequestHeader = async (method, url, data = {}, type = 'urlencoded') => {
    let result = {};
    let headers = {};

    //用户登录状态下，每次更新refresh_token58分钟之后需要更新access_token
    if (getLocalStorageStr('access_token')) {
        let cur_time = new Date().getTime();
        if (cur_time - getLocalStorageStr('time') * 1 > 58 * 60 * 1000) {
            let param = new FormData();
            param.append('grant_type', 'refresh_token');
            param.append('refresh_token', getLocalStorageStr('sld_refresh_token'));
            // param.append('refresh_token', 'eyJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiMTY4MDAwNCIsImV4cCI6MTY1MDM4MDg3OCwidXVpZCI6IjRmNTcyYWVjLWI2YTAtNDZkYy1iZGQ2LTIyYzhhOGJkNjY3YyIsIndlYklkZW50aWZ5Ijoic2VsbGVyIn0.hO--HuFjJdCjlIH1YWMQ3HGp_I8nlqrU9q_itkrE5JymDf-ZHJyzRygd_U5FpyZ2jlz_pJiVKfA0CL-g53WBGGFd5ZWiyg_4zpCvcvGWcoFMGYk0dUA9iMRELCB1Vwh--pqlJbt8hscawe2D92Dc7cpQkC4mbk68JkhqEVFXVj8zLdI2j3XT6ekSuSKMe4xGByTPENRYNqiEw3khMN2iuquiibCqpwbC3cKs-f-33_zvwW5eEDTvXYrpiEeEOX4vro3beTfMxXjcmBkB7_w96fuuc4ATTqcOCqhDSGb7QGaLhEAx9lD6tjqp9Y-H5qSV_2GK1aEcPdH44VI7xQnDLw')
            await instance.post('/v3/sellerLogin/oauth/token', param, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Basic c2VsbGVyOnNlbGxlcg=='
                }
            }).then((response) => {
                if (response.data.state == 200) {
                    localStorage.setItem('sld_token', response.data.data.access_token);
                    localStorage.setItem('sld_refresh_token', response.data.data.refresh_token);
                    localStorage.setItem('time', new Date().getTime().toString());//存储refresh_token更新时间
                }
            }, err => {
                console.log('更新access_token出错：', err);
            })
        }
    }

    if (method == 'post') {
        if (type == 'urlencoded') {
            headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
            data = qs.stringify(data);
        } else if (type == 'json') {
            headers = { 'Content-Type': 'application/json' };
            data = JSON.parse(JSON.stringify(data));
        } else if (type == 'form') {//form-data
            headers = { 'Content-Type': 'multipart/form-data' };
            let tmp_data = new FormData();
            Object.keys(data).forEach((item) => {
                tmp_data.append(item, data[item])
            });
            data = tmp_data;
        }
    }

    if (url.indexOf('/v3/sellerLogin/oauth/token') > -1) {
        headers.Authorization = 'Basic c2VsbGVyOnNlbGxlcg==';
    } else {
        console.info(123,getLocalStorageStr('sld_token'));
        let token = getLocalStorageStr('sld_token');
        // let token = 'eyJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiMTY4MDAwNCIsImV4cCI6MTY1MTY3MzI3OCwidXVpZCI6IjRmNTcyYWVjLWI2YTAtNDZkYy1iZGQ2LTIyYzhhOGJkNjY3YyJ9.b7IOSA7el1W8a8CGUoRuLlOtzUL7UyJ3KxZT0FbJshKAXDOGSsmb8aBSP-G02smw8OxKEslfbHpQGQ1Hm-f7-RFfBHD-BCvWMamPBbIlHXsvy8nEpDIlI5n9B_gNjjTk23juaOTOgYMv2ULZMymox7y6nhdWn_Jn0cW_u38GcDLlW1ShhrXjvpxjc3LJn1XMFL29uA-QW8Ag_-dOnsr22xpFav4cfsvs8ZbqVnnfzjRHmt3Tp2gmr7yxdx1zLM-Vlpb7kP8o8FO6atbjvowKJgLcTo2FHJ7Ef2qrF7p3rg4zXbIyQDQun6zSijKyRhCrpOmIG8avO8HfLt5BWj4zkQ'
        headers.Authorization = 'Bearer ' + token;
    }

    result = { url, data, headers };
    return result;
}
