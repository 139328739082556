/*
 * @Author: your name
 * @Date: 2021-07-28 19:10:26
 * @LastEditTime: 2022-04-20 21:33:37
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /yichenglongyu_im_seller_v2.8/src/utils/config.js
 */
/**
 *  项目的配置文件
 */
export const apiUrl = 'https://console.ilongyu.com.cn/'//接口请求地址
export const chatUrl = 'wss://im.ilongyu.com.cn'//客服地址
export const storeUrl = 'https://console.ilongyu.com.cn/';//对应的商户后台地址
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名

/** copyright *** slodon *** version-v2.8 *** date-2021-07-28 ***主版本v3.0**/


//http://localhost:8082/service?sld_token=eyJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiMTY4MDAwNCIsImV4cCI6MTY1MDQ2NTE5MiwidXVpZCI6ImJmN2M1ZjhmLWQ5ZmQtNGNhMy1hNjZiLWQ1ZGYyMmIwNWExMiIsIndlYklkZW50aWZ5Ijoic2VsbGVyIn0.LfPKG4n7NFWmcD9zNNLGhQa2Cs7-xHo_vyxR1hMwcIEjl3RaUBQx1HmOKGE8-gzZQjxJbYE1FPKEGYu8HZwaNhiJWPrGuH09zYn4j2upRpzSXj0X-Aqnmu5PyJFSy4tYMvMQXGKLebC0tf05nwIWfU27FM4RQtHAPEtiC-aoOYRd3ePJ63E6BQ8cXT_fDvYED4hlWSTGIbBMwd6DhVkjr7MnE2nnyYkgrkUPqO_Aim-YZWqdntb7AIUZppkWJyJGC-ZXVw_Mz_4I7ya3vw9KNzQ4gfpnVWSHYAldknfKN7r67ShR6DBLVzOIp0f9dqANJib0li9zQdxXFQtU6vjHAw&storeId=870004&vendorId=1680004&isStoreAdmin=1